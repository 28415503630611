.calendar-container {
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
}

.activity-calendar {
  width: 100%;
  background: #2c3e50;
  border: 1px solid #34495e;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
}

.activity-marker {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  margin-top: 5px;
}

.activity-filter {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  justify-content: center;
}

.filter-button {
  padding: 8px 16px;
  border: 2px solid;
  border-radius: 20px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
}

.filter-button:hover {
  opacity: 0.8;
}

/* Override react-calendar default styles */
.react-calendar {
  width: 100% !important;
  border: none !important;
  background: #2c3e50 !important;
  color: white !important;
}

.react-calendar__tile {
  padding: 0.5em !important;
  height: 80px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
  color: white !important;
  position: relative !important;
}

.react-calendar__tile abbr {
  position: absolute !important;
  top: 2px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  z-index: 1 !important;
  font-size: 0.8em !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #34495e !important;
}

.react-calendar__tile--active {
  background-color: #3498db !important;
}

.react-calendar__navigation button {
  color: white !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #34495e !important;
}

.react-calendar__month-view__weekdays {
  color: #3498db !important;
}

.react-calendar__tile--now {
  background-color: rgba(39, 125, 212, 0.6) !important;  /* Subtle dark blue with opacity */
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: rgba(52, 73, 94, 0.6) !important;  /* Slightly darker on hover */
}

.activities-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
  padding-top: 28px;
}

.activity-label {
  font-size: 0.5em;  /* Made font smaller */
  line-height: 1.2;  /* Reduced line height */
  padding: 1px 2px;
  background-color: #3498db;
  border-radius: 2px;
  color: white;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 12px;  /* Set minimum height */
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.activity-label.faded {
  opacity: 0.3;
}

.activity-label:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .calendar-container {
    padding: 10px;
  }
  
  .activity-calendar {
    padding: 10px;
  }
  
  .activity-marker {
    font-size: 1em;
  }
}

.activity-popup {
  position: fixed;
  z-index: 1000;
  background: #34495e;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  min-width: 150px;
  transform: translateX(-50%);
}

.popup-content {
  color: white;
}

.popup-content h4 {
  margin: 0 0 8px 0;
  font-size: 14px;
}

.popup-content p {
  margin: 4px 0;
  font-size: 12px;
} 