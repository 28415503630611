.App {
  min-height: 100vh;
  background-color: #282c34;
}

.App-header {
  padding: 20px 0;
  min-height: auto;  /* Override the 100vh from App.css */
  justify-content: flex-start;
}

.swimbikerun-animation {
  transform: translateY(-15px);
}

main {
  margin-top: 20px;
}

.stats-container {
  background-color: #282c34;
  padding: 20px;
  flex: 1;
}

.stats-row {
  position: static;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.stat-item {
  text-align: center;
  padding: 20px;
}

.title-container {
  display: flex;
  align-items: center;
  gap: 10px;  /* Reduced gap between elements */
}

.App-title {
  margin: 0;  /* Remove any default margins */
}