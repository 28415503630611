/* Add specific class for DetailGraph's chart container */
.detail-graph .chart-container {
  margin-top: 20px;
  width: 100%;
  height: 400px; /* Adjust the height as needed */
}

.detail-graph .chart-container canvas {
  width: 100% !important;
  height: 100% !important;
}

@media (max-width: 768px) {
  .detail-graph .chart-container {
    height: 300px; /* Adjust the height for mobile view */
  }
}