.navigation-menu {
  width: 200px;
  height: 100vh;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  z-index: 1000; /* Ensure the menu is on top of other elements */
}

.navigation-menu.hidden {
  transform: translateX(-100%);
}

.navigation-menu ul {
  list-style: none;
  padding: 30px 0 0 0; /* Add top padding to move the navigation options down */
  margin: 0;
  width: 100%;
}

.navigation-menu li {
  width: 100%;
}

.navigation-menu a {
  display: block;
  padding: 15px 20px;
  color: white;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.navigation-menu a:hover {
  background-color: #34495e;
}

.menu-toggle {
  display: block;
  position: fixed;
  top: 10px;
  left: 10px;
  background-color: #2c3e50;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1001; /* Ensure the button is on top of the menu */
}

@media (max-width: 768px) {
  .navigation-menu {
    transform: translateX(-100%);
  }

  .navigation-menu.hidden {
    transform: translateX(0);
  }
}