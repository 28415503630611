.summary-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px auto;
  gap: 15px;
  max-width: 900px;
}

.stat-card {
  background: rgb(91, 89, 89);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  min-width: 200px;
  text-align: center;
}

.chart-container {
  background: transparent;
  margin: 20px auto;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  height: 300px;
  max-width: 900px;
}

.stat-card h3 {
  margin-top: 0;
  color: #ffffff;
}

.stat-card p {
  margin: 10px 0;
  color: #ffffff;
}

@media (max-width: 768px) {
  .chart-container {
    height: 250px;
  }
} 