.yoga-count {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.yoga-count h2 {
  font-size: 8rem;
  color: #61dafb;
  margin: 0; /* Remove any default margin */
}

.yoga-count p {
  font-size: 2rem;
  color: white;
  margin: 0; /* Remove any default margin */
}