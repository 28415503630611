.summary-table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.table {
  width: 80%;
  max-width: 500px;
  border-collapse: collapse;
  margin: 20px 0;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 5px; /* Reduced padding */
}

.table th {
  background-color: #ff4d6d;
  text-align: center;
  color: white;
}